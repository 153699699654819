import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import Video from '../assets/videos/video.mp4'
import Fade from 'react-reveal/Fade';

const Hero = () => {
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline ></VideoBg>
            </HeroBg>
            <HeroContent>
                <Fade>
                    <HeroItems>
                        <HeroTitle>Vein-U Mobile Blood Draw</HeroTitle>
                        <HeroParagraph>At home blood draw services</HeroParagraph>
                        <Button primary="true" big="true" round="true" to="#contact">Call us now (518) 386 8678</Button>
                        <Button  style={{ marginTop : 30 }} primary="true" big="true" round="true" to="#contact">Fax (518) 671 3250</Button>
                    </HeroItems>
                </Fade>
            </HeroContent>
        </HeroContainer>
    )
}

export default Hero;

const HeroContainer = styled.div`
    background-color: #bababa;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 100vh;
    padding: 0 1rem;
    position: relative;
    margin-top: -80px;
    overflow-y: hidden; 

    :before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100% );
    }
`

const HeroBg = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: hidden; 
`

const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

const HeroContent = styled.div`
    z-index: 3;
    height: calc(100vh - 80px);
    max-height: 100%;
    padding: 0rem calc((100vw - 1300px) / 2)
`


const HeroItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    max-height: 100vh;
    padding: 0;
    color: #fff;
    line-height: 1.1;
    font-weight: bold;
`

const HeroTitle = styled.h1`
    font-size: clamp(1.5rem, 6vw, 4rem);
    margin-bottom: 1.5rem;
    letter-spacing: 3px;
    font-weight: bold;
    padding: 0 1rem;
`

const HeroParagraph = styled.p`
    font-size: clamp(1rem, 3vw, 3rem);
    margin-bottom: 2rem;
    font-weight: 400;
`

// const Image = styled.img`
//     border-radius: 10px;
//     height: 10%;

//     @media screen and (max-width: 768px){
//         height: 10%;
//     }

//     @media screen and (max-width: 500px){
//         height: 15%;
//     }
// `

