import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {IoMdCheckmarkCircleOutline} from 'react-icons/io'
import { FaRegLightbulb, FaTint } from 'react-icons/fa'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

const Services = () => {
    const data = useStaticQuery(graphql`
        query ImgQuery {
            allFile(
            filter: {ext: {regex: "/(jpg)|(png)|(jpeg)/"}, name: {in: ["009_purchased_01","005_purchased_01"]}}
            ) {
            edges {
                node {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
                }
            }
            }
        }      
    `)
    return (
        <TestimonialsContainer>
            {/* <TopLine>
                Services
            </TopLine> */}
            <Fade bottom>
                <Description>
                    Services we offer
                </Description>
                
                <ContentWrapper>
                    <ColumnOne>
                        <Testimonial>
                            <IoMdCheckmarkCircleOutline style={{color: "#3fffa8", fontSize: "2rem", marginBottom: "1rem"}}  />
                            <h3>Individuals <h6>Choose Vein-U Mobile Blood Draw because:</h6></h3> 
                            <p>
                                <ul>
                                    <li>For all your lab work related needs, we come to you, and render our services in the comfort of your home</li>
                                    <li>No waiting in busy lab or patient service centers</li>
                                    <li>In this pandemic times, we ensure to adhere to all safety protocol</li>
                                </ul>
                            </p>
                        </Testimonial>
                        <Testimonial>
                            <FaRegLightbulb style={{color: "#f9b19b", fontSize: "2rem", marginBottom: "1rem"}} />
                            <h3>Physicians, Home Health Agencies and Hospice Care Providers <h6>Choose Vein-U Mobile Blood Draw because:</h6></h3> 
                            <p>
                                <ul>
                                    <li>Superlatively skilled in geriatric and pediatric collections</li>
                                    <li>Vein-U Mobile Blood Draw&apos;s Quality Assurance division carefully ensures that we stay within state and federal guidelinesv</li>
                                    <li>Submitting an order over the fax or email is made very easy & user-friendly</li>
                                    <li>We will take specimens to your preferred laboratory, or if you don’t have one, we will ensure to send your specimens to a credible laboratory with good turnaround times</li>
                                    <li>We are familiar with standing orders as well as PT/INR point of care</li>
                                </ul>
                            </p>
                        </Testimonial>
                        <Testimonial>
                            <FaTint style={{color: "#8d93e0", fontSize: "2rem", marginBottom: "1rem"}} />
                            <h3>Physicians Servicing Pediatrics and Special Needs Patients  <h6>Choose Vein-U Mobile Blood Draw because:</h6></h3> 
                            <p>
                                <ul>
                                    <li>We provide our phlebotomists with skilled training</li>
                                    <li>Our phlebotomists provide empathy in a nurturing, caring environment</li>
                                </ul>
                            </p>
                        </Testimonial>
                    </ColumnOne>
                    <ColumnTwo>
                        {data.allFile.edges.map((image, key)=> (
                            <Images key={key} fluid={image.node.childImageSharp.fluid}></Images>
                        ))}
                    </ColumnTwo>
                </ContentWrapper>
            </Fade>
        </TestimonialsContainer>
    )
}

export default Services

const TestimonialsContainer = styled.div`
    // background: #fcfcfc;
    background: #bababa;
    color: #000;
    padding: 2rem calc((100vw - 1300px) / 2);
    /* top: 0;
    bottom: 0;
    right: 0;
    left: 0; */
    width: 100%;
    height: 140vh;

    @media screen and (max-width: 768px){
        height: 200vh;
    }

    @media screen and (max-width: 500px){
        height: 240vh;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }

    @media screen and (max-width: 377px){
        height: 400vh;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }
`
/* const TopLine = styled.p`
    color: #077bf1;
    font-size: 1rem;
    padding-left: 2rem;
    margin-bottom: 0.75rem;
` */
const Description = styled.p`
    text-align: start;
    padding-left: 2rem;
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 5vw, 2rem);
    font-weight: bold;    
`
const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem;
    

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
`
const ColumnOne = styled.div`
    display: grid;
    grid-template-rows: auto;
`
const Testimonial = styled.div`
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    h3{
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-style: italic;
    }
    p{
        color: #3b3b3b;
    }
`
const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
    grid-gap: 10px;

    @media screen and (max-width: 500px){
        grid-template-columns: 1fr;
        
    }
`

const Images = styled(Img)`
    border-radius: 10px;
    height: 100%;

    @media screen and (max-width: 768px){
        height: 50%;
    }

    @media screen and (max-width: 500px){
        height: 80%;
    }
`