import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import BBBLogo from '../assets/images/bgImages/better-business-bureau-seeklogo.png'

const WhyUs = () => {
    return (
        <TestimonialsContainer>
            {/* <TopLine>
                Services
            </TopLine> */}
            <Fade bottom>
                <Description>
                    Why should you choose us?
                </Description>
                
                <ContentWrapper>
                    <ColumnTwo>
                            <Images src={BBBLogo} ></Images>
                    </ColumnTwo>
                    <ColumnOne>
                    <   Testimonial>
                            <h3>To be accredited by BBB, a business or organization affirms that it meets and will abide by the following standards:</h3> 
                            <h2>
                                    <ol>
                                        <li style={ListItems}>Build Trust</li>
                                        <li style={ListItems}>Advertise Honestly</li>
                                        <li style={ListItems}>Tell the Truth</li>
                                        <li style={ListItems}>Be Transparent</li>
                                        <li style={ListItems}>Honor Promises</li>
                                        <li style={ListItems}>Be Responsive</li>
                                        <li style={ListItems}>Safeguard Privacy</li>
                                    </ol>
                            </h2>
                        </Testimonial>
                    </ColumnOne>
                </ContentWrapper>
            </Fade>
        </TestimonialsContainer>
    )
}

export default WhyUs

const TestimonialsContainer = styled.div`
    background: #fcfcfc;
    color: #000;
    padding: 2rem calc((100vw - 1300px) / 2);
    /* top: 0;
    bottom: 0;
    right: 0;
    left: 0; */
    width: 100%;
    height: 140vh;

    @media screen and (max-width: 768px){
        height: 200vh;
    }

    @media screen and (max-width: 500px){
        height: 150vh;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }

    @media screen and (max-width: 377px){
        height: 300vh;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }
`
/* const TopLine = styled.p`
    color: #077bf1;
    font-size: 1rem;
    padding-left: 2rem;
    margin-bottom: 0.75rem;
` */
const Description = styled.p`
    text-align: start;
    padding-left: 2rem;
    margin-bottom: 2rem;
    font-size: clamp(1.5rem, 5vw, 2rem);
    font-weight: bold;    
`
const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 1rem;
    height: 20vh; 
    

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
`
const ColumnOne = styled.div`
    display: grid;
    grid-template-rows: auto;
`
const Testimonial = styled.div`
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    h3{
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-style: italic;
    }
    p{
        color: #3b3b3b;
    }
`
const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: -95rem;
    grid-gap: 10px;
    height: 15%;

    @media screen and (max-width: 500px){
        grid-template-columns: 1fr;
    }

     @media screen and (max-width: 377px){
        grid-template-columns: 1fr;
    }
`

// const Images = styled(Img)`
//     border-radius: 10px;
//     height: 10%;

//     @media screen and (max-width: 768px){
//         height: 50%;
//     }

//     @media screen and (max-width: 500px){
//         height: 80%;
//     }
// `

const Images = styled.img`
    border-radius: 10px;
    height: 30%;
    margin : 10px;
    padding: 10px;

    @media screen and (max-width: 768px){
        height: 50%;
    }

    @media screen and (max-width: 500px){
        height: 25%;
    }
`

const ListItems = {
    margin: 20
}
