import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import WhyUs from "../components/WhyUs"
import Services from "../components/Services"
import HowItWorks from "../components/HowItWorks"
import EmailUs from "../components/Email"
import Footer from "../components/Footer"
import FAQs from "../components/FAQs"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div id="home"></div>
    <Hero></Hero>
    <div id="whyus" />
    <WhyUs></WhyUs>
    <div id="services"></div>
    <Services></Services>
    <div id="howitworks"></div>
    <HowItWorks></HowItWorks>
    <div id="faqs"></div>
    <FAQs></FAQs>
    <div id="signup"></div>
    <EmailUs></EmailUs>
    <div id="contact"></div>
    <Footer></Footer>
  </Layout>
)

export default IndexPage
